<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>All Surveys</h1>
        <v-simple-table
            fixed-header
            height="450px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Customer</th>
              <th class="text-left">Surveyor</th>
              <th class="text-left">Address</th>
              <th class="text-left">Requested by</th>
              <th class="text-left">Date</th>
              <th class="text-left">Completed</th>
              <th class="text-left" style="width:130px">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(survey, index) in surveys"
                :key="index"
            >
              <td>{{ survey.customer  }}</td>
              <td>{{ survey.surveyor }}</td>
              <td>{{ survey.address }}</td>
              <td>{{ survey.requestor }}</td>
              <td>{{ survey.date | prettyDate }}</td>
              <td>
                <span v-if="survey.completed === 0 || survey.completed === false">
                  No
                </span>
                <span v-else>
                  Yes
                </span>
              </td>
              <td class="pr-0">
                <router-link custom v-slot="{ navigate }" :to="`/surveys/view/${survey.id}`">
                  <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                    View
                  </v-btn>
                </router-link>
                &nbsp;
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SurveysIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      surveys: [],
      page: 1,
      length: 0,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getQuotations();
    },
  },
  methods: {
    getSurveys() {
      axios.get(`/siteSurveys/getAll/${this.page}.json?token=${this.token}`)
          .then((response) => {
            this.surveys = response.data.surveys;
            this.length = response.data.length;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getSurveys();
  },
};
</script>
